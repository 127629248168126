<template>
    <div class="header">
        <div class="header-container">
            <img class="logo" :src="logoPath" alt="logo">
            <div class="navigation">
                <div class="p">MENU</div>
                <nav>
                    <ul class="navigation" id="ul-navigation">
                        <li><router-link to="/" class="btn">{{ $t('HomeMenu') }}</router-link></li>
                        <li><router-link to="/about" class="btn">{{ $t('AboutMeMenu') }}</router-link></li>
                        <li><router-link to="/services" class="btn">{{ $t('ServicesMenu') }}</router-link></li>
                        <li><router-link to="/contact" class="btn">{{ $t('ContactMenu') }}</router-link></li>
                    </ul>
                    <p>|</p>
                    <ul class="languages flag-icon-button" v-for="entry in languages" :key="entry.title" @click="$emit('doChangeLocale', entry.language); changeLogo(entry.language);
                    selectedLocale = $event;">
                        <li :iso="entry.flag" v-bind:squared="false"> {{entry.title}}</li> 
                    </ul>
                </nav>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "HeaderComponent",
    data() {
        return {
            selectedLocale: "en",
            logoPath: require("@/assets/logo_eng.png"),
            languages: [
                { flag: 'fr', language: 'fr', title: 'FR' },
                { flag: 'us', language: 'en', title: 'EN' }
            ]
        }
    },
    methods: {
        changeLogo(_language) {
            if(_language === "fr") {
                this.logoPath = require("@/assets/logo_fr.png");
            }else {
                this.logoPath = require("@/assets/logo_eng.png");
            }
        }
    },
    mounted () {
        let header = document.getElementById("ul-navigation");
        let btns = header.getElementsByClassName("btn");
        for (let i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function() {
                let current = document.getElementsByClassName("active");
                if (current.length > 0) { 
                    current[0].className = current[0].className.replace(" active", "");
                }
                this.className += " active";
            });
        }
    }
}
</script>


<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
.btn{
    transition: all .2s ease;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    padding-bottom: .4rem;
}

    .active, .btn:hover {
        padding-bottom: .4rem;
        border-bottom:2px solid #666;
        color: #666 !important;
       font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        // font-size: 20.9px !important;
    }

    .header{
        .header-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 2rem;
            border-bottom: 2px solid #444E62;
            height: 5rem;
        }

        position: fixed;
        top: 0;
        z-index: 1000;
        width: 100%;
        background: #243956;
        color: #ffffff;

        img.logo{
            width: 400px !important;
            height: auto;
        }

        .navigation:hover{
            nav{display: flex;}
        }

        nav{
            display: none;
            flex-direction: column;
            position: absolute;
            right: 10px;
            top: 10px;
            background: #243956;
            p{
                display: none;
                margin: 0; padding: 0;
            }
            ul{
                padding: 1rem;
                list-style: none;
                display: flex;
                flex-direction: column;
                transition: all .5s ease;
                margin-bottom: 0;

                li{
                    margin: 1rem ;
                    padding: 0;
                    a   { 
                        text-decoration: none; 
                        color: #ffffff; 
                    }
                    font-weight: bold;
                    text-transform: uppercase;
                }

                li:hover, li:active{
                    a{
                        color: #8b97a5;

                    }
                }
                
            }
            ul.languages{
                padding-left: 0;
                padding-right: 0;
                    padding: 0;
                    margin-bottom: 1rem;
                li{
                    margin: 0;
                }
                .flag-icon-button{
                    margin: 0;
                    padding: 0;
                }
                
            }
             li:hover, li:active{
                        color: #8b97a5;
                }

            
        }
    }

    @media only screen and (min-width: 950px) {
        .header {
            .header-container{
                height: 10rem;
            }
            img.logo{
                width: 250px;
            }
        }
         nav{
            display: flex !important;
                flex-direction: row !important;

                p{
                    margin-top: 3.2rem !important;
                   display: block !important;
               }
            ul{
                list-style: none;
                display: flex;
                flex-direction: row !important;
                transition: all .5s ease;
                li{
                    margin: 0 1rem ;
                    a{text-decoration: none; color: #ffffff;}
                }

                li:hover, li:active{
                    a{
                        color: #444E62;

                    }
                }
                
            }
            ul.languages{
                padding-left: 0;
                li{
                    padding: 2rem 1rem;
                }
            }

            
        }
    }

    @media screen and (max-width: 600px) {
        .header {
            .header-container{
                height: 10rem;
            }
            img.logo{
                width: 150px !important;
            }
        }
    }
</style>