<template>
    <div class="home">
        <div class="space"></div>
        <div class="hero">
            <img src="./../assets/hero.jpg" alt="hero">
            <p class="heading">{{ $t('affirmation') }}</p>
        </div>
        <about-component />
        <div class="negative-space">.</div>
        <services-component />
        <div class="negative-space">.</div>
        <contact-component />
    </div>
</template>

<script>
import AboutComponent from '../components/AboutComponent.vue'
import ContactComponent from '../components/ContactComponent.vue'
import ServicesComponent from '../components/ServicesComponent.vue'
import AOS from "aos";
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default {
  components: { AboutComponent, ServicesComponent, ContactComponent },
    name: 'NewHome',
    mounted () {
        AOS.refresh();
    },
}
</script>

<style lang="scss" scoped>
.home{
      background: #243956;
}
.negative-space{
    margin-top: -25rem;
}
    .hero{
        min-height: calc(100vh - 5rem);
        width: 100%;
        position: relative;
        overflow: hidden;
        img{
            height: calc(100vh - 5rem);
            width: 100%;
            Object-fit: cover;
        }
        p.heading{
            width: fit-content;
            position: absolute;    
            bottom: 1rem;
            color: #ffffff;
            font-size: 2rem;
            margin: 0 auto;
            left: 50%;
            transform: translate(-50%, -50%);
        }

    }
    @media only screen and (min-width: 600px) {

        .hero {
             min-height: calc(100vh - 10rem);
             img{
                height: calc(100vh - 10rem);
            }
             p.heading{
                bottom: 3rem;
                font-size: 3rem;
            }
        }
    }
</style>