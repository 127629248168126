<template>
    <div class="about" id="about">
        <div class="space"></div>
        <div class="text">
            <h2 data-aos="fade-up">Pascal de Lucia</h2>
            <p data-aos="fade-right">
                {{ $t('Intro') }} 
            </p>
            <p data-aos="fade-right">
                {{ $t('Intro2') }} 
            </p>
            <p data-aos="fade-right">
                {{ $t('Intro3') }} 
            </p>
            <p data-aos="fade-right">
                {{ $t('Intro4') }} 
            </p>
        </div>
        <div class="image" data-aos="fade-left">
            <img src="./../assets/Pascal.png" alt="" class="pascal">
        </div>
    </div>
</template>


<script>
export default {
    name: 'AboutComponent'
}
</script>

<style lang="scss" scoped>
    .about{
        background: #243956;
        color: #ffffff;
        display: grid;
        min-height: 100vh ;
        grid-template-columns:1fr;
        grid-template-rows: auto;
        padding: 2rem;
        margin-bottom: 8rem;

        .text{
            text-align: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2{
                font-size: 2.6rem;
                font-weight: 900;
                margin-top: 0;
                margin-bottom: 0;
            }
            p{font-size: 1rem; margin: .8rem auto; }
        }
        

        
        img.pascal{
            border: 5px solid #ffffff;
            width: 240px;
            object-fit: cover;
            margin-top: 2rem;
        }
    }
    
    @media only screen and (min-width: 600px) {
        .about{
            padding: 2rem 4rem;
             grid-template-columns:0 1fr 1fr;
            }
         .text{
            text-align: left !important; 
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2{
                font-size: 2.6rem;
                font-weight: 900;
                margin-top: 0;
            }
            p{font-size: 1.4rem !important;}
        }
        .image{
            display: flex;
            justify-content: center;
            align-items: center;
        }
         img.pascal{
            border: 5px solid #ffffff;
            width: 400px !important;
            object-fit: cover;
            margin-top: 0;
        }
    }
</style>