<template>
    <footer>
        <div class="linkedIn">
            <a href="https://www.linkedin.com/in/pascal-de-lucia-4a32b818/" target="_blank" >
                <img src="../assets/LinkedIn_icon_circle.png"   alt="../assets/LinkedIn_icon_circle.png">
            </a>
        </div>
        <p>
            {{$t('Footer')}}
        </p>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style lang="scss" scoped>
    footer{
        height: 8rem;
        padding: 4rem;
        text-align: center;
        color: black;
        font-weight: bold;
        background-color: white;
        position: relative;
        margin-top: -5rem;
        z-index: 1;
        

        p{
            margin-top: 10rem;
            font-size: .8rem;
        }
        .linkedIn{
            position: absolute;
            top: 80px;
            left: 30px;
            img{
                width: 40px;
            }
        }
    }

    @media only screen and (min-width: 600px) {
        footer{
            margin-top: -10rem;
            p{
                font-size: 1rem;
            }

            .linkedIn{
                top: 40px;
                left: 60px ;
            }
        }
    }
</style>