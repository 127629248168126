import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';

import i18n from '@/plugins/i18n';
import notification from 'vue-notification-ui'
import FlagIcon from 'vue-flag-icon';

import iosAlertView from 'vue-ios-alertview';

Vue.use(iosAlertView);

Vue.use(notification, {
  position: 'notification-top-right', // top, bottom, left, right
  duration: 5000, // default
  left: 20, // default
  bottom: 20, // default
  top: 20, // default
  right: 40 // default
})

Vue.use(FlagIcon);
Vue.config.productionTip = false
Vue.use(ElementUI, { locale });

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
