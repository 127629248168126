<template>
    <div class="contact" id="contact">
        <div class="space"></div>
        <div class="container">
            <div class="address" data-aos="fade-right">
                <p class="bold">Pascal de Lucia</p>
                <p>Rue du Conseil-Général 8 <br>CH-1205 Genève</p>
                <p><a :href="`tel:+441225529070`" style="color: #ffff; text-decoration: none">
                    +41 (0)22 552 90 70
                </a></p>
                <p><a :href="`tel:+41794695599`" style="color: #ffff; text-decoration: none">
                    +41 (0)79 469 55 99
                </a></p>
                <p @click.prevent="sendEmail" style="cursor: pointer">pdelucia@pdllaw.ch</p>
            </div>
            <div class="form" data-aos="fade-left">
                <form action="" id="contactForm">
                    <div class="form-group">
                        <div class="form-input">
                            <label for="">{{ $t('Name') }} </label><br>
                            <input type="text" :placeholder=" $t('NameQuestion') " v-model="name" ref="name">
                        </div>
                        <div class="form-input">
                            <label for=""> {{ $t('PhoneNumber') }} </label><br>
                            <input type="text" placeholder="555-555-5555" v-model="phone">
                        </div>
                    </div>
                    <div class="form-input">
                        <label for="">EMAIL</label><br>
                        <input type="text" placeholder="example@website.com" v-model="email">
                    </div>
                    
                    <div class="form-input">
                        <label for="">MESSAGE</label><br>
                        <textarea  :placeholder=" $t('MessagePlaceholder') " v-model="message"></textarea>
                    </div>
                    <input type="submit" class="submit" :value="$t('SendMessage')"  @click.prevent="sendEmail">
                </form>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'ContactComponent',
    data() {
        return {
            name: "",
            phone: "",
            email: "",
            message: "",
            lawyer: "Pascal de Lucia",
            numberOfTries: 0
        }
    },
    methods: {
        reloadPage() {
            this.$router.go(this.$router.currentRoute)
        },
        sendAlert(title, message, callback = () => {}) {
            this.$iosAlert({
                title,
                text: message
            }).then(function(){
                callback();
            });
        },
        sendEmail() {

            const limit = 1;

            if(this.numberOfTries < limit) {

                if(this.name && this.email && this.message && this.phone) {

                    this.$iosRemind({
                        title: `Hi, ${this.name}`,
                        text: `Please wait. Sending email to ${this.lawyer}`,
                        remindDuration: 3000
                    })

                    const templateParams = {
                        to_name: this.lawyer,
                        from_name: this.name,
                        phone_number: this.phone,
                        from_email: this.email,
                        message: this.message,
                        reply_to: this.email
                    };
                
                    // eslint-disable-next-line no-undef
                    emailjs.send('service_9v1zb1j', 'template_s7qj9w8', templateParams)
                    .then((response) => {

                        // console.log('SUCCESS!', response.status, response.text);

                        if(response.status === 200) {

                            this.sendAlert(
                                `Hi, ${this.name}`,
                                `Your message has been received. We'll get back to you soon`
                            )
                            this.numberOfTries++;
                        }

                    }, () => {

                        // console.log(error)

                        this.sendAlert(
                            `Sorry ${this.name}`,
                            `Message failed to send. Please try again later or call +41 79 469 55 99`,
                            this.reloadPage
                        )
                    });

                }else {

                    this.sendAlert(
                        `Hi ${this.name}`,
                        `Please fill all fields`,
                        this.focusInput
                    )
                }

            }else {
                const limitString = this.numberOfTries >  1 ? "times": "time";
                this.sendAlert(
                    `Sorry, ${this.name}`,
                    `You can't use the form more than ${limit} ${limitString}`
                )
            }
        },

        focusInput() {
            this.$refs.name.focus();
        }
    },
    mounted () {}
}
</script>

<style lang="scss" scoped>

.contact{
    background: #243956;
    height: fit-content;
}

.container{
position: relative;
color: #ffffff;
min-height: calc( 100vh - 20rem) ;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: auto;
justify-content: center;

padding: 2rem;

.address{
    padding: 4rem;
    padding-bottom: 0;
    text-align: center;
    font-size: 1rem;

    p{
        margin: .7rem 0;
    }
}
p.bold{font-weight: bolder;}

.form{
        display: inline-block;
        margin: 0 auto;
    background: #BDC4CC;
    color: #243956;
    min-width: 20rem;
    max-width: 20rem;
    padding: 1rem;
    height: fit-content;
    overflow: hidden;
        margin-top: 4rem;
        position: relative;
            z-index: 2;

    form{
        max-width: 98%;
        text-align: left;
    }
    .form-group{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .form-input{
        width: 95%;
        text-align: left;
        input, textarea{
            width: 100%;
            padding: .7rem;
            font-size: .8rem;
            border: none;
            margin-bottom: 1rem;
        }
        label{
            font-weight: bold;
            font-size: .8rem;
        }
        textarea{
            height: 5rem;
        }
        input::placeholder, ::placeholder{
            font-family: 'Gotham Light', sans-serif;
            font-size: 1rem;
        }
    }
    input[type=submit]{
        padding: .6rem;
        background: #243956;
        color: #ffffff;
        border: none;
        font-size: .8rem;
    }
}
}

@media screen and (max-width: 600px) {
    .address {
        margin-top: 10rem;
    }
}

@media only screen and (max-width: 1100px) {
            .form-group{
                flex-direction: column !important;
                gap: 10px !important;
            }
}

@media only screen and (min-width: 600px) {
    .contact{
            min-height: calc( 100vh - 10rem ) ;
    }
    
        .container{
        color: #ffffff;
        min-height: fit-content ;
        display: grid;
        grid-template-columns:1fr 1fr;
        grid-template-rows: auto;

        padding: 4rem;

        .address{
            text-align: left;
            font-size: 1.7rem;
            margin-top: 16rem;
            height: fit-content;
        }
        p.bold{font-weight: bolder;}

        .form{
            background: #BDC4CC;
            color: #243956;
            max-width: 100%;
            padding: 6rem 4rem;
            height: fit-content;
            overflow: hidden;

            form{
                width: 100% !important;
                text-align: left;
            }
            .form-input{
                width: 100%;
                text-align: left;
                input, textarea{
                    max-width: 100%;
                    padding: .7rem;
                    font-size: 1.3rem;
                    border: none;
                    margin-top: 1.3rem;
                    margin-bottom: 2.2rem;
                }
                label{
                    font-weight: bolder;
                    font-size:1.2rem ;
                }
                textarea{
                    height: 10rem;
                }

                
            }
            .form-group{
                display: flex;
                flex-direction: row;
                justify-content: space-between;   
                gap: 40px !important;

                input{
                    width: 100%;
                }
            }
            input[type=submit]{
                padding: 1rem;
                background: #243956;
                color: #ffffff;
                border: none;
                font-size: 1.3rem;
            }
        }
    }
}

               
</style>