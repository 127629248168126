<template>
  <div id="app">
    <HeaderComponent @doChangeLocale="changeLocale"/>
    <router-view/>
    <FooterComponent/>
  </div>
</template>


<script>
import i18n from '@/plugins/i18n';
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';


export default {
  components: { HeaderComponent, FooterComponent },
  data() { return {} },
  methods: {
    changeLocale(locale) {
        i18n.locale = locale;
        console.log({locale})
    }
  },
}
</script>
<style>
.space{
    height: 5px;
  }
#app {
  font-family: 'Gotham Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #243955;
  width: 100vw;
  overflow: hidden;
}

body {
  margin: auto;
    /* background: #243956; */
  font-size: 20px; 
  line-height: 1.5;
}

button {
    padding: 15px;
    border: 1px solid green;
    font-size: 18px;
    margin: 15px;
}

.flag-icon-button {
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .space {
     height: 10rem;
  }
}
</style>
