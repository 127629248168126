import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        HomeMenu: 'Home',
        AboutMeMenu: 'About Me',
        ServicesMenu: 'Services',
        ContactMenu: 'Contact',
        Intro: "Pascal is a Swiss lawyer with 20 years of international and Swiss tax law experience. He has a special focus in providing tax and legal advice to clients and representing them in their litigations with tax authorities.",
        Intro2: 'Pascal has previously worked as a senior wealth consultant for a leading bank, a tax expert for a multinational company, associate at different law firms, and as a consultant at a Big Four.',
        Intro3: 'His working languages are French, English and Italian. He is a member of the Geneva Bar Association and an Associate Judge specialized in tax matters at the Administrative Court of Geneva.', 
        Intro4: 'Pascal received his Law Degree from the University of Geneva and was admitted to the Geneva Bar in 1998. He also holds an MBA in International Management.',
        affirmation: 'Handling your unique legal needs.',
        ServicesTitle: "Let PdL Law counsel and represent you in ligations in the following areas:",
        FooterMessage: "PDL LAW. All rights reserved",
        Corporate: "Corporate, entrepreneur, and private tax.",
        Taxation: "Taxation of real estate (holding and selling).",
        Inheritance: "Inheritance and transfer of assets.",
        Contract: "Contract negotiations and compliance. " ,
        Foundations: "Foundations, associations and non-profits.",
        Footer: "© 2022 PdL Law. All rights reseved.",
        Name: "FULL NAME",
        NameQuestion: "What is your name?",
        PhoneNumber: "PHONE NUMBER ",
        MessagePlaceholder: "Type your message here...",
        SendMessage: "SEND MESSAGE"
    },
    'fr': {
        HomeMenu: 'Accueil',
        AboutMeMenu: 'PRÉSENTATION',
        ServicesMenu: 'ACTIVITÉS',
        ContactMenu: 'Contact',
        Intro: "Pascal est un avocat suisse avec 20 ans d’expérience en droit fiscal suisse et international. Il conseille et assiste ses clients dans le cadre de leur planification fiscale et les représente en cas de contentieux par devant les autorités administratives et judiciaires compétentes.",
        Intro2: 'Pascal a précédemment travaillé comme conseiller fiscal auprès d’une banque de premier plan, tax manager pour la Suisse auprès d’une multinationale et avocat-fiscaliste dans plusieurs cabinets d’avocats de renom.',
        Intro3: 'Ses langues de travail sont le français, l’anglais et l’italien. Il est membre de l’Ordre des avocats de Genève et exerce la fonction de juge assesseur en matière fiscale auprès du Tribunal administratif de Genève.',
        Intro4: 'Pascal a obtenu son brevet d’avocat en 1998. Il est titulaire d’une licence en droit de l’Université de Genève et d’un MBA en management international.',
        affirmation: 'Votre situation appelle un conseil sur mesure.',
        ServicesTitle: "PdL Law conseille et représente ses clients dans les domaines suivants:",
        FooterMessage: "PDL LAW. Tous les droits sont réservés.",
        Corporate: "Fiscalité des entreprises, des indépendants  et des individus.",
        Taxation: "Fiscalité de l’immobilier (acquisition, vente).",
        Inheritance: "Succession et transfert de patrimoine.",
        Contract: "Négociation  et rédaction  de contrats.",
        Foundations: "Exonération de fondations  et d’associations d’intérêt public.",
        Footer: "© 2022 PdL Law. Tous droits réservés.",
        Name: "NOM, Prenom",
        NameQuestion: "Quel est ton nom?",
        PhoneNumber: "Numéro de téléphone",
        MessagePlaceholder: "Tapez votre message ici...",
        SendMessage: "Envoyer"
    },
    'it': {
        HomeMenu: 'Casa',
        AboutMeMenu: 'Su di me',
        ServicesMenu: 'Servizi',
        ContactMenu: 'Contatto',
        Intro: "Pascal è un avvocato svizzero con 20 anni di esperienza in diritto tributario internazionale e svizzero. Ha un focus speciale sulla negoziazione di ruling fiscali per individui, imprenditori e aziende con un elevato patrimonio netto. È membro dell'Ordine degli avvocati di Ginevra e giudice associato specializzato in questioni fiscali presso il Tribunale amministrativo di Ginevra. Il vantaggio di Pascal deriva dalla sua vasta esperienza. In precedenza ha lavorato come consulente patrimoniale senior per un importante istituto bancario, esperto fiscale per una società multinazionale, associato presso diversi studi legali e come consulente presso una Big Four. Parla francese, inglese e italiano. Ha conseguito la laurea in giurisprudenza presso l'Università di Ginevra nel 1996 ed è stato ammesso all'Ordine degli avvocati di Ginevra nel 1998. Nel 2015 ha completato un MBA in International Management presso l'Università di Ginevra.",
        affirmation: 'La mia promessa ad ogni cliente è di lavorare instancabilmente per ottenere un risultato giusto.',
        ServicesTitle: "Lascia che PDL LAW ti aiuti con le tue questioni civili e fiscali, incluse le seguenti:",
        FooterMessage: "PDL LAW. Tutti i diritti riservati"
    }
};

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages, // set locale messages
});
export default i18n;