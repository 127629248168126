<template>
    <div class="services" id="services">
        <div class="space"></div>
        <h3 id="servicesTitle">{{ $t('ServicesTitle') }} </h3>
        <div class="services-grid">
            <div class="service" data-aos="fade-up" data-aos-duration="1500">
                <div class="image">
                    <img src="../assets/calc.jpg"  alt="../assets/calc.jpg">
                </div>
                <div class="type">
                    <p> {{ $t('Corporate') }} </p>
                </div>
            </div>

            <div class="service" data-aos="fade-up" data-aos-duration="1500">
                <div class="image">
                    <img src="../assets/gavel.jpg"   alt="../assets/gavel.jpg">
                </div>
                <div class="type">
                    <p> {{ $t('Taxation') }} </p>
                </div>
            </div>

            <div class="service" data-aos="fade-up" data-aos-duration="1500">
                <div class="image">
                    <img src="../assets/coins.jpg"   alt="../assets/coins.jpg">
                </div>
                <div class="type">
                    <p> {{ $t('Inheritance') }}</p>
                </div>
            </div>

            <div class="service" data-aos="fade-up" data-aos-duration="1500">
                <div class="image">
                    <img src="../assets/sign.jpg"   alt="../assets/sign.jpg">
                </div>
                <div class="type">
                    <p> {{ $t('Contract') }}</p>
                </div>
            </div>

            <div class="service" data-aos="fade-up" data-aos-duration="1500">
                <div class="image">
                    <img src="../assets/shake.jpg"   alt="../assets/shake.jpg">
                </div>
                <div class="type">
                    <p> {{ $t('Foundations') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServicesComponent",
    mounted () {},
    data() {
        return{
            // items: [
            //     { id: 1 ,image: 'https://nuvest.net/wp-content/uploads/2017/03/Nuvest_Why-Financial-Accounting-and-Management-Accounting-are-Essential-for-Business.jpeg', type: 'Corporate, entrepreneur, and private tax.' },
            //     { id: 2 ,image: 'https://pixy.org/src2/574/thumbs350/5745604.jpg', type: 'Taxation of real estate (holding and selling)' },
            //     { id: 3 ,image: 'https://larl.org/wp-content/uploads/2018/12/tax-web.jpg', type: 'Inheritance and transfer of assets.' },
            //     { id: 4 ,image: 'https://equityplusbd.com/wp-content/uploads/2021/02/endorser-definition-1210x642.jpg', type: 'Contract negotions and compliance.' },
            //     { id: 5 ,image: 'https://i2.wp.com/www.hiringpeople.co.uk/wp-content/uploads/2021/12/A-Guide-on-How-to-Hire.jpg?fit=1200%2C795&ssl=1', type: 'Foundations and non-profits' },

            // ]    
        }
    },
    computed: {
        getServiceDescription(key) {
            let description;
            switch (key) {
                case 1:
                    description = this.t('HomeMenu');
                    break;
            
                default:
                    description = "";
                    break;
            }
            return description;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 600px) {
        .services {
            #servicesTitle{
                margin-top: 10rem;
            }
        }
    }
    .services{
        min-height: calc( 100vh - 20rem ) ;
        background: #243956;
        color: #ffffff;
        padding: 4rem;
        margin-bottom: 4rem;
        h3{
            margin: 0;
            margin-bottom: 4rem;
        }
        .services-grid{
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-wrap: wrap;
            .service{
                flex: 1 1 150px;
                position: relative;
                // box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
                
                .image{
                    img{
                        position: relative;
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        object-fit: cover;
                        z-index: 40;
                    }
                }
                .type{
                    background: #909CAA;
                    width: 100%;
                    height: 200px;
                    position: relative;
                    top: -65px;
                    z-index: 0;
                    p{
                        font-size: 1rem;
                        padding: 20px;
                        padding-top: 70px;
                    }
                }
            }
        }

        @media only screen and (min-width: 600px) {
            .services-grid{
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap;
            .service{
                min-width: 200px;
                max-width: 300px;
                position: relative;
                
                
                .image{
                    img{
                        position: relative;
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        object-fit: cover;
                        z-index: 40;
                    }
                }
                .type{
                    background: #909CAA;
                    width: 100%;
                    height: 350px;
                    position: relative;
                    top: -95px;
                    z-index: 0;
                    p{
                        font-size: 1.4rem;
                        padding: 20px;
                        padding-top: 100px;
                    }
                }
            }
        }
        }
    }
</style>